<template>
  <div class="activity_home" v-if="hd.id">
  
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(image, index) in hd.banner" :key="index">
        <img v-lazy="image" width="100%"/>
      </van-swipe-item>
    </van-swipe>
  
    <div class="countDown">
      <van-count-down :time="time">
        <template #default="timeData">
          <span v-if="hd.state==2">活动已结束</span>
          <template v-else>
            <span>距{{hd.state==0?'开始':'结束'}}：</span>
            <span class="block">{{ timeData.days }}</span>
            <span class="colon">天</span>
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">时</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">分</span>
            <span class="block">{{ timeData.seconds }}</span>
            <span class="colon">秒</span>
          </template>
        </template>
      </van-count-down>
    </div>
  
    <div class="zhuanpan">
      <div class="lottery" :style="'transform:rotate('+config.angle+'deg);-ms-transform:rotate('+config.angle+'deg);-webkit-transform:rotate('+config.angle+'deg);-moz-transform:rotate('+config.angle+'deg);-o-transform:rotate('+config.angle+'deg);transition:transform ease-out '+config.during_time+'s;-moz-transition:-moz-transform ease-out '+config.during_time+'s;-webkit-transition:-webkit-transform ease-out '+config.during_time+'s;-o-transition:-o-transform ease-out '+config.during_time+'s'">
        <div class="line">
          <div class="li" v-for="index in gList.length" :key="index" :style="'transform: rotate('+(1/gList.length*index - (1/gList.length)/2)+'turn);'"></div>
        </div>
        <div class="g">
          <div class="item" v-for="(item,index) in gList" :key="index">
            <div class="img" :style="'transform: rotate('+(1/gList.length*index)+'turn);'"><img :src="item.titleimg" width="100%"/></div>
          </div>
        </div>
      </div>
      <a :class="['actionPoint',(config.flag && config.cj_num>0 && config.zj_num>0)?'':'stop']" @click="onClickElement"></a>
    </div>
    
    <div class="container">
      <div class="cjnum">
        <span v-if="config.cj_num>0">您还有 {{config.cj_num}} 次抽奖机会！</span>
        <span v-else>您的抽奖次数已用完</span>
      </div>

      <van-row class="user_statistics">
        <van-col :span="goodList.length?6:8" class="item">
          <label>{{hd.total_user}}</label>
          <span>已参与</span>
        </van-col>
        <van-col :span="goodList.length?6:8" class="item">
          <label>{{hd.tj_num}}</label>
          <span>已中奖</span>
        </van-col>
        <van-col span="6" class="item" v-if="goodList.length>0">
          <label>{{hd.seckill_tj_num}}</label>
          <span>已购买</span>
        </van-col>
        <van-col :span="goodList.length?6:8" class="item">
          <label>{{hd.amount}}</label>
          <span>已阅读</span>
        </van-col>
      </van-row>

      <div class="column">
        <div class="h4">他们都感兴趣</div>
      </div>
      <div class="index_box">
        <userList :hdid="hd.id"/>
      </div>
      
      <template v-if="hd.is_showgood">
        <div class="column">
          <div class="h4">奖品区</div>
        </div>
        <goodList :type="1" :dataList="gList" :data="hd" :config="{action:false,is_stock:false,now_price:true,old_price:false,is_prize:true}" v-if="gList.length"/>
      </template>
  
      <template v-if="goodList.length>0">
        <div class="column">
          <div class="h4">秒杀 ● 抢购区</div>
        </div>
        <goodList :type="2" :dataList="goodList" :data="hd" v-if="goodList.length"/>
      </template>
      
      <template v-if="hd.seckill_tj_num>0">
        <div class="column">
          <div class="h4">已有 <em>{{hd.seckill_tj_num}}</em> 人购买</div>
        </div>
        <div class="index_box">
          <orderuser :hdid="hd.id" :order_type="1"/>
        </div>
      </template>
      
      <template v-if="hd.content != null && hd.content != ''">
        <div class="column">
          <div class="h4">活动介绍</div>
        </div>
        <div class="textContent index_box" v-html="hd.content"></div>
      </template>
      
      <div class="mt10"><shopInfo :data="hd.shopInfo"/></div>
    </div>
  </div>
</template>

<script>
  import shopInfo from '@/pages/wx/components/shopInfo'
  import goodList from '@/pages/wx/components/goodList'
  import userList from '@/pages/wx/components/userList'
  import orderuser from '@/pages/wx/components/orderuserList'
export default {
  props: {
    hd:Object,
    config:Object,
    goodList:Array,
    gList:Array
  },
  components: {goodList,userList,orderuser,shopInfo},
  data() {
    return {
      time:0
    }
  },
  activated() {
    this.setBgColor()
  },
  created(){
    this.setBgColor()
  },
  mounted() {
    this.time = new Date(this.hd.end_time).getTime() - new Date().getTime();
  },
  methods: {
    setBgColor(){
      if(this.hd.bgcolor==null || this.hd.bgcolor==''){
        document.querySelector('body').setAttribute('style', 'background-color:#ffe7bb')
      }
    },
    onClickElement(){
      this.$emit('onPoint',{type:1})
    }
  }
}
</script>
<style lang="less">
  @import "./css/newyear.less";
</style>
